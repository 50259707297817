import { Link, PageProps } from "gatsby";
import { ModalRoutingContext } from "gatsby-plugin-modal-routing";
import React, { useState } from "react";

type DataProps = {
  site?: {
    buildTime: string
  }
}

const Impressum: React.FC<PageProps<DataProps>> = ({ path }) => {
  const [modal, setModal] = useState(false)

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <div>
          {modal ? (
            <Link to={closeTo}>Schließen</Link>
          ) : (
            <>
              <header>
                <br />
                <hr />
                <h1>Impressum gemäß § 24 Abs. 3 Mediengesetz</h1>
              </header>
              <br />
              <hr />
              <p>Medieninhaber: Christian Lechner </p>
              <p>Anschrift: 1030 Wien, Baumgasse 36/1</p>
            </>
          )}

          <Link to="/">
            <h2>Zurück</h2>
          </Link>
        </div>
      )}
    </ModalRoutingContext.Consumer>
  )
}

export default Impressum
